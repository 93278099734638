<template>
  <mobile-screen
    :header="true"
    :footer="false"
    screen-class="icon-app1 outlook-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="outlook-header icon-hea1">
        <div class="component-title">
          {{ displayLabelName("outlook", "outlook", "your-meeting") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section tiny-top-spacing transparent no-border">
      <li
        class="clebex-item-section-item full-right-underline transparent advanced-search"
      >
        <button
          @click="$router.push({ name: 'r_outlook-advanced-search' })"
          class="body-btn"
        >
          <icon icon="#cx-app1-search-16x16" width="20" height="20" />
          {{ displayLabelName("outlook", "outlook", "advanced-search") }}
        </button>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing">
      <li class="clebex-item-section-item full-right-underline title">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label"
              >{{ displayLabelName("outlook", "outlook", "title") }}
              <span>*</span></label
            >
            <input
              ref="titleInput"
              @keyup="changeTitle($event.target.value)"
              type="text"
              :value="title"
            />
          </div>
        </div>
      </li>
    </ul>
    <ul v-if="warningNumber" class="clebex-item-section tiny-top-spacing">
      <li class="clebex-item-section-item warning-center">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div class="warning-center">
                {{ displayLabelName("outlook", "outlook", "warning-center") }}
              </div>
            </span>
            <div class="warning-count-wrapper">
              <div class="warning-count-number">{{ warningNumber }}</div>
              <icon icon="#cx-men1-warning" width="10" height="10" />
            </div>
          </div>
        </div>
      </li>
      <li
        v-for="(warningMessage, i) in warningMessages"
        :key="i"
        class="clebex-item-section-item no-separator warning-line"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="red"
                icon="#cx-app1-warning-red"
                width="20"
                height="20"
              />
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label p-0">
              <div>
                {{ warningMessage.message.replace(/.$/, "") }}:
                <span class="resource-name">{{
                  warningMessage.resourceName.join(", ")
                }}</span>
              </div>
            </label>
          </div>
        </div>
      </li>
      <!-- <li class="clebex-item-section-item no-separator warning-line">
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                class="warning"
                icon="#cx-men1-warning"
                width="20"
                height="20"
              />
            </span>
          </span>
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label p-0">
              <div>You have <span>3</span> unavailable attendees</div>
            </label>
          </div>
        </div>
      </li> -->
    </ul>
    <ul
      v-if="
        (requiredAttendees && requiredAttendees.length) ||
          (optionalAttendees && optionalAttendees.length)
      "
      class="clebex-item-section tiny-top-spacing"
    >
      <li class="clebex-item-section-item attendees-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "outlook", "attendees") }}
              </div>
            </span>
            <div class="attendees-warning-wrapper">
              <icon
                class="yellow"
                icon="#cx-app1-warning"
                width="20"
                height="20"
              />
              <div
                v-if="requiredAttendees && optionalAttendees"
                class="warning-count-wrapper"
              >
                <div class="warning-count-number">
                  {{
                    `${requiredAttendees.length}+${
                      optionalAttendees.length
                    }/${requiredAttendees.length + optionalAttendees.length}`
                  }}
                </div>
                <icon icon="#cx-app1-user-10x10" width="16" height="16" />
              </div>
            </div>
          </div>
        </div>
      </li>
      <li
        v-if="
          (requiredAttendees && requiredAttendees.length) ||
            (optionalAttendees && optionalAttendees.length)
        "
        class="clebex-item-section-item no-separator attendees"
      >
        <div class="clebex-item-content-wrapper">
          <section v-if="requiredAttendees && requiredAttendees.length">
            <div
              v-for="(attendee, i) in requiredAttendees"
              :key="i"
              class="attendee"
            >
              <icon
                @click="removeAttendee(attendee, 'requiredAttendees')"
                class="error"
                icon="#cx-msg1-declined-100x100"
                width="16"
                height="16"
              />
              <div>{{ attendee.displayName }}</div>
            </div>
          </section>
          <section v-if="optionalAttendees && optionalAttendees.length">
            <div
              v-for="(attendee, i) in optionalAttendees"
              :key="i"
              class="attendee"
            >
              <icon
                @click="removeAttendee(attendee, 'optionalAttendees')"
                class="error"
                icon="#cx-msg1-declined-100x100"
                width="16"
                height="16"
              />
              <div>{{ attendee.displayName }}</div>
            </div>
          </section>
        </div>
      </li>
      <!-- <li class="clebex-item-section-item no-separator attendee-slots">
        <div class="slot-wrapper">
          <div class="attendee-number-wrapper">
            <div class="attendee-number">5</div>
            <icon icon="#cx-app1-login-page" width="10" height="10" />
          </div>
          <div class="time-slot">10:30 AM - 11:30 AM</div>
        </div>
        <div class="slot-wrapper">
          <div class="attendee-number-wrapper">
            <div class="attendee-number">6</div>
            <icon icon="#cx-app1-login-page" width="10" height="10" />
          </div>
          <div class="time-slot">01:00 PM - 02:00 PM</div>
        </div>
        <div class="slot-wrapper">
          <div class="attendee-number-wrapper">
            <div class="attendee-number">7</div>
            <icon icon="#cx-app1-login-page" width="10" height="10" />
          </div>
          <div class="time-slot">18 Fev 2021 | 01:00 PM - 02:00 PM</div>
        </div>
      </li> -->
    </ul>
    <ul
      v-if="duration"
      @click="$router.push({ name: 'r_outlook-duration' })"
      class="clebex-item-section tiny-top-spacing cursor-pointer"
    >
      <li class="clebex-item-section-item duration">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "duration", "date-time") }}
              </div>
            </span>
            <div class="warning-count-wrapper">
              <div class="warning-count-number">
                {{ duration.hours.toString().length === 1 ? "0" : ""
                }}{{ duration.hours }}:{{
                  duration.minutes.toString().length === 1 ? "0" : ""
                }}{{ duration.minutes }} H
              </div>
              <icon icon="#cx-app1-time" width="14" height="14" />
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item day-picker">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li v-if="globalDateFormat" class="clebex-item-range from">
              <button class="clebex-item-range-cta">
                {{ displayDate(start) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-calendar" width="20" height="20" />
            </li>
            <li v-if="globalDateFormat" class="clebex-item-range to">
              <button class="clebex-item-range-cta">
                {{ displayDate(end) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="clebex-item-section-item time-picker">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button class="clebex-item-range-cta">
                {{ displayTime(start) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="20" height="20" />
            </li>
            <li class="clebex-item-range to">
              <button class="clebex-item-range-cta">
                {{ displayTime(end) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing">
      <li
        @click="editRecurrence(true)"
        class="clebex-item-section-item slide-icon-border full-right-underline recurrence"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer">{{
              displayLabelName("outlook", "outlook", "doesnt-repeat")
            }}</label>
          </div>
          <span v-if="!recurrence" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-checkmark" width="12" height="12" />
            </span>
          </span>
        </div>
      </li>
      <li
        @click="editRecurrence()"
        class="clebex-item-section-item slide-icon-border full-right-underline recurrence"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer">{{
              displayLabelName("outlook", "outlook", "recurrence-same-time")
            }}</label>
          </div>
          <span v-if="recurrence" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-checkmark" width="12" height="12" />
            </span>
          </span>
        </div>
      </li>
      <!-- <li
        class="clebex-item-section-item slide-icon-border full-right-underline recurrence"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer">{{
              displayLabelName("outlook", "outlook", "multiple-bookings")
            }}</label>
          </div>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-checkmark" width="12" height="12" />
            </span>
          </span>
        </div>
      </li> -->
    </ul>
    <ul
      v-if="resources && resources.length"
      class="clebex-item-section tiny-top-spacing"
    >
      <li class="clebex-item-section-item resources-section">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{
                  displayLabelName("outlook", "outlook", "resources-selected")
                }}
              </div>
            </span>
            <div
              v-if="!resources.find((el) => el.checker)"
              class="resources-warning-wrapper"
            >
              <icon
                class="red"
                icon="#cx-app1-warning-red"
                width="20"
                height="20"
              />
            </div>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item no-separator resources">
        <div
          v-for="resource in resources"
          :key="resource.id"
          class="resource-wrapper"
          :class="{ disabled: !resource.checker }"
        >
          <icon
            @click="handleResources(resource.email)"
            class="error"
            icon="#cx-msg1-declined-100x100"
            width="20"
            height="20"
          />
          <div class="resource-name" style="flex: 1">
            {{ resource.name }},
            <span>{{
              resource.full_path
                .split(", ")
                .filter((el, i) => i > 0)
                .join(", ")
            }}</span>
          </div>
          <icon
            @click="openResourceInfo(resource.id)"
            icon="#cx-app1-information"
            width="20"
            height="20"
          />
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";

export default {
  name: "Outlook",
  data() {
    return {};
  },
  computed: {
    ...mapState("outlook", [
      "title",
      "requiredAttendees",
      "optionalAttendees",
      "start",
      "end",
      "enhancedLocation",
      "resources",
      "loading",
      "recurrence",
    ]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    duration() {
      if (this.start && this.end) {
        return DateTime.fromISO(this.end)
          .diff(DateTime.fromISO(this.start), ["hours", "minutes"])
          .toObject();
      }
      return null;
    },
    warningNumber() {
      if (this.resources.filter((el) => !el.checker).length) {
        return this.resources.filter((el) => !el.checker).length;
      }
      return null;
    },
    warningMessages() {
      if (this.resources.filter((el) => !el.checker).length) {
        let warnings = [];
        this.resources
          .filter((el) => !el.checker)
          .forEach((element) => {
            const warningIndex = warnings.findIndex(
              (el) => el.message === element.checker_message[0]
            );
            if (warningIndex !== -1) {
              warnings[warningIndex].resourceName = [
                ...warnings[warningIndex].resourceName,
                element.name,
              ];
            } else {
              warnings.push({
                message: element.checker_message[0],
                resourceName: [element.name],
              });
            }
          });
        return warnings;
      }
      return null;
    },
  },
  created() {
    window.addEventListener("message", this.readMessage);
  },
  mounted() {
    parent.postMessage(
      {
        call: "loaded",
        value: true,
      },
      "*"
    );
  },
  methods: {
    ...mapActions("outlook", [
      "setTitle",
      "setRequiredAttendees",
      "setOptionalAttendees",
      "setStart",
      "setEnd",
      "setEnhancedLocation",
      "setRecurrence",
      "getResource",
    ]),
    ...mapActions("auth", ["logout"]),
    openResourceInfo(id) {
      this.$router.push({
        name: "r_outlook-resource-info",
        params: { resource_id: id },
      });
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(DateTime.fromISO(date), this.globalDateFormat);
    },
    displayTime(time) {
      if (!time || !this.globalTimeFormat) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    readMessage(event) {
      if (event.data.call === "outlookData") {
        this.setTitle(event.data.title);
        this.setRequiredAttendees(event.data.requiredAttendees);
        this.setOptionalAttendees(event.data.optionalAttendees);
        this.setStart(
          DateTime.fromJSDate(event.data.start).toISO({
            suppressMilliseconds: true,
          })
        );
        this.setEnd(
          DateTime.fromJSDate(event.data.end).toISO({
            suppressMilliseconds: true,
          })
        );
        this.setEnhancedLocation(event.data.enhancedLocation);
        this.setRecurrence(event.data.recurrence);
        this.handleResources(false, event);
      }
    },
    changeTitle(title) {
      parent.postMessage(
        {
          call: "subject",
          value: title,
        },
        "*"
      );
    },
    setLocation(location) {
      parent.postMessage(
        {
          call: "enhancedLocation",
          value: location,
        },
        "*"
      );
    },
    handleResources(email = false, event) {
      if (this.loading) {
        return;
      }
      if (email) {
        const val = this.enhancedLocation.find(
          (el) => el.locationIdentifier.id === email
        ).locationIdentifier;
        if (val) {
          parent.postMessage(
            {
              call: "enhancedLocation",
              value: JSON.parse(JSON.stringify(val)),
            },
            "*"
          );
        }
        return;
      }

      if (event.data.event === "timeChanged") {
        this.$store.commit("outlook/setResources", [], {
          root: true,
        });
      }
      if (this.enhancedLocation) {
        const locationEmails = this.enhancedLocation.map(
          (el) => el.locationIdentifier.id
        );
        const resourceEmails = this.resources.map((el) => el.email);
        const emailsToAdd = locationEmails.filter(
          (el) => !resourceEmails.includes(el)
        );
        const emailsToRemove = resourceEmails.filter(
          (el) => !locationEmails.includes(el)
        );
        if (emailsToAdd.length) {
          emailsToAdd.forEach((element) => {
            const payload = {
              email: element,
              datetime_from: this.start,
              datetime_to: this.end,
              recurrence: this.recurrence ? this.recurrence : {},
            };
            this.getResource(payload);
          });
        }
        if (emailsToRemove.length) {
          const newResources = this.resources.filter(
            (el) => !emailsToRemove.includes(el.email)
          );
          this.$store.commit("outlook/setResources", newResources, {
            root: true,
          });
        }
      }
    },
    removeAttendee(attendee, event) {
      let val = [];
      if (event === "requiredAttendees") {
        val = this.requiredAttendees
          .map((el) => el.emailAddress)
          .filter((el) => el !== attendee.emailAddress);
      } else if (event === "optionalAttendees") {
        val = this.optionalAttendees
          .map((el) => el.emailAddress)
          .filter((el) => el !== attendee.emailAddress);
      }
      parent.postMessage(
        {
          call: event,
          value: JSON.parse(JSON.stringify(val)),
        },
        "*"
      );
    },
    editRecurrence(remove) {
      if (remove) {
        parent.postMessage(
          {
            call: "recurrence",
            value: null,
          },
          "*"
        );
      } else {
        this.$router.push({ name: "r_outlook-recurrence" });
      }
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  beforeUnmount() {
    window.removeEventListener("message", this.readMessage);
  },
};
</script>
